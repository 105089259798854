import { Document } from "@tiptap/extension-document";
import { Heading, type Level } from "@tiptap/extension-heading";
import { Text } from "@tiptap/extension-text";
import { Bold } from "@tiptap/extension-bold";
import { Italic } from "@tiptap/extension-italic";
import { Strike } from "@tiptap/extension-strike";
import { Twemoji } from "~/tiptap/twemoji";
import { Paragraph } from "@tiptap/extension-paragraph";
import { UserMention } from "~/tiptap/mention";
import { Typography } from "@tiptap/extension-typography";

export const titleExtensions = (options?: Partial<{
  customHeadingLevel?: Level
}>) => {
  return markRaw([
    // nodes
    Document.configure({
      content: 'heading'
    }),
    Heading.configure({
      levels: [options?.customHeadingLevel || 1]
    }),
    Text,
    Twemoji,
    Typography,
  ])
}

export const teaserExtensions = (options?: Partial<{
}>) => markRaw([
  // nodes
  Document,
  Paragraph,
  Text,
  Twemoji,
  UserMention,
  // marks
  Bold,
  Italic,
  Strike,
  Typography,
])
